.TitleSection{
  width: auto;
}

.TitleSectionGroup{
  margin: 0px;
  display: grid;
  gap: 0;
  grid-template-columns: 89px auto;
  align-items: center;
  justify-items: center;
}

.TitleItem{
  display: flex;
  width: 100%;
  height: 100%;
  outline: 4px solid #A4A6A8;
  outline-offset: -2px;
  align-self: center;

  
}

.TitleItem img{
  margin:8px;
}
.TitleItem > div{
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #F8FF03; 
  align-items: center;
  justify-content: center;
  margin: 8px;
  padding: 8px;
}
.TitleItem > div > div{
  font-size: 15px;
  font-weight: 700;
  width: 100%;
  color: #282828;
}

.TitleItem > div > div:first-child {
  font-size: 14px;
}

@media(max-width:320px){
  .TitleItem > div > div:first-child {
    font-size: 12px;
  }
}