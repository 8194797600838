@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

html{
  height: calc(var(--vh, 1vh) * 100);
}

body{
  font-family: 'Noto Sans TC', sans-serif;
  margin: 0px;
  height: 100%;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  line-height: 1.3;
  background: url('./assets/images/background.png');
  background-position: center;
  background-repeat: repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  outline: none;
}

.next-link-img {
  animation: breathing 1s infinite alternate;;
}

@keyframes breathing { 
  from { opacity: 0.1; } 
}

.PageContainer{
  display:grid;
  grid-template-rows: minmax(622px, calc(var(--vh, 1vh) * 100));
  margin: 0px;
  padding: 0px;
  align-items: stretch;
  justify-items: stretch;
}

.page-slider-exit > .page {
  z-index: 1;
}

.page-slider-enter > .page {
  z-index: 10;
}

.page-slider-enter.left > .page {
  transform: translate3d(100%, 0, 0);
}

.page-slider-enter.right > .page {
  transform: translate3d(-100%, 0, 0);
}

.page-slider-enter.up > .page {
  transform: translate3d(0, 100%, 0);
}

.page-slider-exit.left > .page {
  transform: translate3d(-100%, 0, 0);
}

.page-slider-exit.right > .page {
  transform: translate3d(100%, 0, 0);
}

.page-slider-exit.up > .page {
  transform: translate3d(0, -100%, 0);
}

.page-slider-enter.page-slider-enter-active > .page {
  transform: translate3d(0, 0, 0);
  transition: all 400ms ease-in-out;
}

.ModalContainer{
  display:grid;
  grid-template-rows: calc(var(--vh, 1vh) * 100);
  margin: 0px;
  padding: 0px;
  align-items: stretch;
  justify-items: stretch;
}


.MenuModal{
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 14px;
  background-color: #1b1b1b;
  outline: 4px solid #A4A6A8;
  outline-offset: -2px;
}

.MenuModalHeader{
  margin: 0px;
  display: grid;
  gap: 0;
  grid-template-columns: auto 59px;
  align-items: center;
  justify-items: center;
}

.MenuModalHeader button {
  display: flex;
  width: 100%;
  border: 0;
  border-left: 4px solid #A4A6A8;
  border-bottom: 4px solid #A4A6A8;
  padding: 0px;
  cursor: pointer;
  background:#1b1b1b;
  align-items: center;
}

.MenuModalHeader img{
  padding: 9px;
  max-width: 100%;
  width: auto;
  margin-left: 0px;
}