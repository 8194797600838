/* change the layout to give more spacing between NextArrow & boxes */
.Canvas-BehindTheStage{
  margin: 0px;
  padding: 14px;
  display: grid;
  gap: 0;
  grid-template-rows: 59px auto 176px 40px;
}

.Content-BehindTheStage{
  display: flex;
  width: 100%;
  height: 100%;
  outline: 4px solid #A4A6A8;
  outline-offset: -2px;
}

.Player{
  display: block;
  width: 100%;
  height: 100%;
  outline: 4px solid #A4A6A8;
  outline-offset: -2px;
}

.Player iframe{
  display: block;
  width: 100%;
  height: 100px;
  border: 0;
  outline: 0;
}

@media(min-width:768px){
  .Canvas-BehindTheStage{
      grid-template-rows: 59px auto 176px 60px;    
  }

}

@media(min-width:1024px){
  .Canvas-BehindTheStage{
      grid-template-rows: 59px auto 176px 60px;    
  }

}