/* change the layout to give more spacing between NextArrow & boxes */
.Canvas-Home{
  margin: 0px;
  padding: 14px;
  display: grid;
  gap: 0;
  grid-template-rows: auto 40px;
  align-items: center;
}

.Canvas-Home img{
  width:100%;
  height: auto;
  margin: auto;
}
.home-img {
  background-image: url('../../assets/images/homeImage.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
}

/* change the layout to give more spacing between NextArrow & boxes */
.NextArrow{
  padding-top: 10px;
  height: 30px;
  margin: 0px auto;
}

.NextArrow a{
  height: 100%;
}

.NextArrow img{
  width: auto;
  height: 100%;
}

@media(min-width:768px){
  .Canvas-Home img{
      max-width: 550px;
  }
  .NextArrow {
      height: 50px;
  }
  .Canvas-Home{
      grid-template-rows: auto 60px;    
  }
}

@media(min-width:1024px){
  .Canvas-Home img{
      max-width: 760px;
  }
  .NextArrow {
      height: 60px;
  }
  .Canvas-Home{
      grid-template-rows: auto 60px;    
  }

}
