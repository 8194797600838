.PodcastCoverSection {
  display: flex;
  flex-direction: column;
  background-color: #F8FF03;
  margin: 8px;
  width: auto;
  background-position: center;
  background-size: cover;
}

.PodcastInfo{
  display: flex;
  align-self: center;
}

.PodcastInfo p{
  font-size: 13px;
  font-weight: 700;
  color: white;
  line-height: 1.6;
  text-align: center;
  letter-spacing: 1px;
  width: 100%;
} 

@media(max-width:320px){

}

@media(min-width:768px){
  .PodcastInfo p {
      font-size: 16px;
  }

}