.StageLinksGroup{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: scroll;
  justify-content: space-between;
  padding-bottom: 24px;
}

.StageLinksGroup a {
  text-decoration: none;
}

.StageLink{
  display: flex;
  padding: 8px 8px;
  margin: 0px 32px;
  border-bottom: 1px solid #4D4D4D;
  justify-items: center;
  align-items: center;
}

.StageLink h1{
  color: #c4c4c4;
  font-size: 40px;
  font-weight: 700;
  margin: 0px 16px 0px 0px;
}

.StageLink h5{
  color: #c4c4c4;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.5;
  margin: 0px;
}
