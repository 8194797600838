/* change the layout to give more spacing between NextArrow & boxes */
.Canvas-PoppingIntoTheSite-b{
  margin: 0px;
  padding: 14px;
  display: grid;
  gap: 0;
  grid-template-rows: 59px 69px auto 100px 40px;
}

.Content-PoppingIntoTheSite-b{
  display: grid;
  grid-template-rows: 44px auto;
  width: 100%;
  height: 100%;
  outline: 4px solid #A4A6A8;
  outline-offset: -2px;
  align-content: stretch;
  justify-self: stretch;
}

.Content-PoppingIntoTheSite-b p{
  position: relative;
  font-size: 13px;
  font-weight: 700;
  color: white;
  line-height: 1.6;
  text-align: center;
  letter-spacing: 1px;
  padding: 16px 16px 8px 16px;
  margin: 0px;
}

.Player{
  display: block;
  width: 100%;
  height: 100%;
  outline: 4px solid #A4A6A8;
  outline-offset: -2px;
}

.Player iframe {
  height: 100%;
}

.carousel {
  width: calc(100vw - 32px);
  align-self: center;
  justify-self: center;
}
.slick-slide .slick-item-b {
  transform: scale(1);
  transition: all .15s 0s ease-in-out;
}
.slick-item-b.unselected {
  transform: scale(0.72);
  opacity: 0.4;
}
.slick-item-b.unselected > p {
  display: none;
}
.slick-item-b > img {
  width: 100%;
}
@media(min-width:768px){
  .Canvas-PoppingIntoTheSite-b{
    grid-template-rows: 59px 69px auto 100px 60px;
  }
}