.Header{
  width: auto;
}

.HeaderGroup{
  margin: 0px;
  display: grid;
  gap: 0;
  grid-template-columns: auto 59px 59px;
  align-items: center;
  justify-items: center;
}

.Header .HeaderGroup > a,
.Header .HeaderGroup > div{
  display: flex;
  width: 100%;
  height: 100%;
  outline: 4px solid #A4A6A8;
  outline-offset: -2px;
}

.Header img{
  padding:12px;
}