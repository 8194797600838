/* change the layout to give more spacing between NextArrow & boxes */
.Canvas-PoppingIntoThePlace{
  margin: 0px;
  padding: 14px;
  display: grid;
  gap: 0;
  grid-template-rows: 59px 69px auto 40px;
}

.Playlist{
  display: block;
  width: 100%;
  height: 100%;
  outline: 4px solid #A4A6A8;
  outline-offset: -2px;
  overflow: hidden;
}

.Playlist iframe{
  width: 100%;
  height: 100%;
  border: 0px;
  outline: 0px;
}

@media(min-width:768px){
  .Canvas-PoppingIntoThePlace{
      grid-template-rows: 59px 69px auto 60px;    
  }

}

@media(min-width:1024px){
  .Canvas-PoppingIntoThePlace{
      grid-template-rows: 59px 69px auto 60px;    
  }

}
