/* change the layout to give more spacing between NextArrow & boxes */
.Canvas-PopStory{
  margin: 0px;
  padding: 14px;
  display: grid;
  gap: 0;
  grid-template-rows: 59px 69px auto 40px;
}
.Canvas-PopStory.scanned {
  grid-template-rows: 59px 69px auto 100px 40px;
}

.Content-PopStory{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  outline: 4px solid #A4A6A8;
  outline-offset: -2px;
  align-items: center;
  justify-content: space-between;
}

.Content-PopStory img{
  width: 50%;
  max-width: 300px;
  height: auto;
}

.Content-PopStory p{
  font-size: 13px;
  font-weight: 700;
  color: white;
  line-height: 1.6;
  text-align: center;
  letter-spacing: 1px;
  padding: 16px 16px 8px 16px;
  margin: 0px;
}

.ButtonGroup{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}

.ButtonGroup button{
  color: rgba(248, 255, 3, 1);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2px;
  padding: 12px 32px;
  background:rgba(248,255,3,0.2);
  border-style: solid;
  border-width: 1px;
  border-radius: 30px;
  border-color: rgba(248, 255, 3, 1);
  cursor: pointer;
}

.ButtonGroup small{
  font-size: 12px;
  padding: 12px;
  color: #A4A6A8;
}

.Player{
  display: block;
  width: 100%;
  height: 100%;
  outline: 4px solid #A4A6A8;
  outline-offset: -2px;
}

@media(max-width:320px){
  .Content-PopStory img{
      width: 40%;
  }

}

@media(min-width:768px){
  .Canvas-PopStory{
      grid-template-rows: 59px 69px auto 60px;    
  }
}

@media(min-width:1024px){
  .Canvas-PopStory{
      grid-template-rows: 59px 69px auto 60px;    
  }

}