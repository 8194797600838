/* change the layout to give more spacing between NextArrow & boxes */
.Canvas-OurMeledy{
  margin: 0px;
  padding: 14px;
  display: grid;
  gap: 0;
  grid-template-rows: 59px 69px auto;
}

.Content-OurMeledy{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  outline: 4px solid #A4A6A8;
  outline-offset: -2px;
}

.Content-OurMeledy p{
  font-size: 13px;
  font-weight: 700;
  color: white;
  line-height: 1.6;
  text-align: center;
  letter-spacing: 1px;
  padding: 16px 16px 8px 16px;
  margin: 0px;
}

.AllCategories{
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 16px 32px;
  justify-content: space-around;
}

.AllCategories > div {
  display: flex;
  align-items: center;
}

.Canvas-OurMeledy-Category{
  margin: 0px;
  padding: 14px;
  display: grid;
  gap: 0;
  grid-template-rows: 59px auto;
}

.Playlist{
  display: block;
  width: 100%;
  height: 100%;
  outline: 4px solid #A4A6A8;
  outline-offset: -2px;

}

.Song-Info{
  display: block;
  width: 100%;
  height: 100%;
  outline: 4px solid #A4A6A8;
  outline-offset: -2px;
}

.meledy-carousel {
  width: calc(100vw - 24px);
}

.Category{
  display: flex;
  align-items: center;
}

.Category img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4px;
  height: 72px;
  opacity: 1;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  align-items: center;
  flex: 1;
}

.Category h5{
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.45;
  letter-spacing: 4px;
  width: 100%;
  color:rgba(255, 255, 255, 1);
  margin:0px;
}

@media(max-width:320px){
  .Category img {
    height: 60px;
  }
  .Category h5{
    font-size: 16px;
  }
}

@media(min-width:414px){
  .Category img {
    height: 80px;
  }
  .Category h5{
    font-size: 20px;
  }
}

@media(max-width:320px){
  .AllCategories {
      margin: 8px 16px;
      grid-gap: 16px;
  }
  .meledy-carousel {
    width: calc(100vw - 16px);
  }
}

@media(min-width:768px){
  .AllCategories {
      grid-template-columns: repeat(3, 1fr);
  }
}

