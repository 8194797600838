/* change the layout to give more spacing between NextArrow & boxes */
.Canvas-PoppingIntoTheScene{
  margin: 0px;
  padding: 14px;
  display: grid;
  gap: 0;
  grid-template-rows: 59px 69px auto 176px 40px;
}

.Content-PoppingIntoTheScene{
  display: grid;
  grid-template-rows: 56px auto;
  outline: 4px solid #A4A6A8;
  outline-offset: -2px;
  align-content: stretch;
  justify-self: stretch;
}

.Content-PoppingIntoTheScene > div {
  padding: 8px;
  margin: 0px;
  text-align: center;
}

.Content-PoppingIntoTheScene > div > div{
  font-size: 13px;
  font-weight: 700;
  color: white;
  line-height: 20px;
  letter-spacing: 1px;
}

.CoverGroup{
  margin: 8px;
  width: 100%;
}

.CoverImage{
  width: 100%;
}

.Player{
  display: block;
  width: 100%;
  height: 100%;
  outline: 4px solid #A4A6A8;
  outline-offset: -2px;
}


@media(max-width:320px){
  .Content-PoppingIntoTheScene p{
      padding: 16px 8px 8px 8px;
  }

}

@media(min-width:768px){
  .Canvas-PoppingIntoTheScene{
      grid-template-rows: 59px 69px auto 179px 60px;    
  }

}

@media(min-width:1024px){
  .Canvas-PoppingIntoTheScene{
      grid-template-rows: 59px 69px auto 180px 60px;    
  }

}