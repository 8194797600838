.InfoCard{
  position: relative;
  display: flex;
  flex-direction: column;    
  background-color: #F8FF03;
  margin: 8px;
  width: 100%;    
}

.Title{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  align-self: stretch;
  margin: 20px 40px;
}

.top-dot {
  position: absolute;
  top: 0;
  min-height: 12px;
  width: 100%;
}

.bottom-dot {
  position: absolute;
  bottom: 0;
  min-height: 12px;
  width: 100%;
}

.top-dot::before,
.top-dot::after,
.bottom-dot::before,
.bottom-dot::after {
  position: absolute;
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  background-color: #282828;
  border-radius: 50%;
}

.top-dot::before {
  top: 12px;
  left: 12px;
}
.top-dot::after {
  top: 12px;
  right: 12px;
}
.bottom-dot::before {
  bottom: 12px;
  left: 12px;
}
.bottom-dot::after {
  bottom: 12px;
  right: 12px;
}

.Title h2{
  font-weight: 900;
  font-size: 55px;
  line-height: 1.47;
  /* identical to box height */

  text-align: center;
  letter-spacing: 1px;
  margin: 0px;
  color: #000000;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

  
.StageNameGroup{
  /* Auto Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
}

.StageNameGroup h5{
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  letter-spacing: 1px;
  margin: 0px;
  color: #282828;
  align-self: stretch;
}

.InfoCard p{
  font-weight: normal;
  font-size: 14px;
  line-height: 1.47;
  letter-spacing: 1px;
  color: #282828;

/* Inside Auto Layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 40px 20px 40px;
}

@media(max-width:320px){
  .Title h2{
      font-size: 40px;
      line-height: 1.3;
  }

  .Title h5{
      font-size: 14px;
  }

  .Title{
      margin: 20px 30px 10px 30px;
  }

  .InfoCard p{
      font-size: 13px;
      margin: 0px 30px 20px 30px;
  }

}

@media(min-width:768px){
  .Title h2{
      font-size: 60px;
  }

  .StageNameGroup h5{
      font-size: 32px;
  }

  .Title{
      margin: 80px 80px 40px 80px;
  }

  

  .InfoCard p{
      font-size: 24px;
      margin: 0px 80px 40px 80px;
      }

}