.FirstHeader{
  width: auto;
}

.FirstHeaderGroup{
  margin: 0px;
  display: grid;
  gap: 0;
  grid-template-columns: auto 59px;
  align-items: center;
  justify-items: center;
}

.FirstHeaderGroup a,
.FirstHeaderGroup > div{
  display: flex;
  width: 100%;
  height: 100%;
  outline: 4px solid #A4A6A8;
  outline-offset: -2px;
}

.FirstHeaderGroup img{
  padding:12px;
  max-width: 100%;
  width: auto;
  margin-left: 0px;
}