.Content-MapPage{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  outline: 4px solid #A4A6A8;
  outline-offset: -2px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  justify-self: stretch;
}

.ImageFrame-Map{
  display: flex;
  margin: 8px;
  align-self: stretch;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.ImageFrame-Map img{
  max-width: 800px;
  width: 85%;
}

.MapControlGroup{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 24px;
  align-self: stretch;
}

.MapControl{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;    

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px 12px 0px;

}

.MapControl button{
  background-color: rgba(255, 255, 255, 0);
  border: none;
  padding: 4px;
}

.MapControl img{
  width: 32px;
}

.MapControl p{
  font-size: 15px;
  font-weight: 400;
  line-height: 1.55;
  color: white;
  text-align: center;
  margin: 0;
}

.ProgressBar{
  align-self: stretch;
  height: 8px;
  background-color:rgba(164, 166, 168, 0.3);
  border-radius: 4px;
}

.ProgressBar .process{
  background-color: #FFFFFF;
  height: 8px;
  border-radius: 4px;
  width: 0px;
  position: relative;
}

.ProgressBar .process::after{
  content: '';
  display: block;
  width: 2px;
  height: 12px;
  background: #A4A6A8;
  border: 3px solid #1A1A1A;
  position: absolute;
  top: -4px;
  right: 0;
}
.ProgressBar .process.stage0::after{
  right: -4px;
}
.ProgressBar .process.stage1{
  width: 12.5%;
}
.ProgressBar .process.stage2{
  width: 25%;
}
.ProgressBar .process.stage3{
  width: 37.5%;
}
.ProgressBar .process.stage4{
  width: 50%;
}
.ProgressBar .process.stage5{
  width: 62.5%;
}
.ProgressBar .process.stage6{
  width: 75%;
}
.ProgressBar .process.stage7{
  width: 87.5%;
}
.ProgressBar .process.stage8{
  width: 100%;
}

.FloorButtons{
  width: auto;
}

.FloorButtonsGroup{
  margin: 0px;
  display: grid;
  gap: 0;
  grid-template-columns: repeat(2,auto);
  align-items: center;
  justify-items: center;
}

.FloorButtonsGroup > div{
  display: flex;
  width: 100%;
  height: 100%;
  outline: 4px solid #A4A6A8;
  outline-offset: -2px;
  text-decoration: none;
}

.FloorButtonsGroup button {
  display: block;
  width: 100%;
  padding: 5px;
  margin: 8px;
  font-size: 118px;
  font-weight: 900;
  line-height: 0.75;
  color:#A4A6A8;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.FloorButtonsGroup button.active {
  color:#221F20;
  background-color:#A4A6A8;
}

@media(max-width:320px){
  .FloorButtonsGroup button {
      font-size: 80px;
  }
}
