/* change the layout to give more spacing between NextArrow & boxes */
.Canvas-Teleport{
  margin: 0px;
  padding: 14px;
  display: grid;
  gap: 0;
  grid-template-rows: 59px auto 160px 40px;
}

.Content-Teleport{
  display: flex;
  width: 100%;
  height: 100%;
  outline: 4px solid #A4A6A8;
  outline-offset: -2px;
  align-content: stretch;
}

.PopPopSection{
  display: flex;
  width: 100%;
  height: 100%;
  outline: 4px solid #A4A6A8;
  outline-offset: -2px;
}


.ImageFrame-PopPop{
  display: flex;
  width: 100%;
  margin: 8px;
  background-image: url('../../assets/images/popImage.svg');
  background-size: contain;
  background-repeat: repeat-x;
  background-position: center; 
}

.ImageFrame-PopPop img{
  width: 100%;
}

@media(min-width:768px){
  .Canvas-Teleport{
      grid-template-rows: 59px auto 160px 60px;    
  }

}

@media(min-width:1024px){
  .Canvas-Teleport{
      grid-template-rows: 59px auto 160px 60px;    
  }
}