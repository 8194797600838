.MapModalContainer .MenuModalHeader button {
  border-bottom: 0;
}
.MenuModalHeader{
  justify-items: left;
}

@media(max-width:320px){
  .MapModalContainer .MenuModal {
    grid-template-rows: 59px 114px auto;
  }
}