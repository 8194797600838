/* change the layout to give more spacing between NextArrow & boxes */
.Canvas-MapPage{
  margin: 0px;
  padding: 14px;
  display: grid;
  gap: 0;
  grid-template-rows: 59px 114px auto 62px;
}

.StartBtn a{
  display: flex;
  width: 100%;
  height: 100%;
  outline: 4px solid #A4A6A8;
  outline-offset: -2px;
}

.StartBtn img{
  padding: 10px;
  margin: auto;
}

@media(max-width:320px){
  .StartBtn img{
      height: 32px;
      width: auto;
  }
  .Canvas-MapPage{
      grid-template-rows: 59px 86px auto 52px;
  }
}