.BackHeader{
  width: auto;
}

.BackHeaderGroup{
  margin: 0px;
  display: grid;
  gap: 0;
  grid-template-columns: 59px auto;
  align-items: center;
  justify-items: center;
}

.BackHeaderGroup a{
  display: flex;
  width: 100%;
  height: 100%;
  outline: 4px solid #A4A6A8;
  outline-offset: -2px;
}

.BackHeaderGroup img{
  padding:12px;
  max-width: 100%;
  width: auto;
  margin-left: 0px;
}
.CategoryName{
  display: flex;
  width: 100%;
  height: 100%;
  outline: 4px solid #A4A6A8;
  outline-offset: -2px;
}

.CategoryName h5{
  font-size: 18px;
  font-weight: 700;
  line-height: 1.95;
  display: block;
  width: 100%;
  margin: 8px;
  padding: 4px 12px;
  color: #F8FF03;
}
