/* change the layout to give more spacing between NextArrow & boxes */
.Canvas-PoppingIntoTheSite-a{
  margin: 0px;
  padding: 14px;
  display: grid;
  gap: 0;
  grid-template-rows: 59px 69px auto 176px 40px;
}

.Content-PoppingIntoTheSite-a{
  display: grid;
  grid-template-rows: 44px auto;
  width: 100%;
  height: 100%;
  outline: 4px solid #A4A6A8;
  outline-offset: -2px;
  align-content: stretch;
  justify-self: stretch;
}

.Content-PoppingIntoTheSite-a p{
  position: relative;
  font-size: 13px;
  font-weight: 700;
  color: white;
  line-height: 1.6;
  text-align: center;
  letter-spacing: 1px;
  padding: 16px 16px 8px 16px;
  margin: 0px;
}

.Player{
  display: block;
  width: 100%;
  height: 100%;
  outline: 4px solid #A4A6A8;
  outline-offset: -2px;
}

.Player iframe {
  height: 100%;
}

.carousel {
  width: calc(100vw - 32px);
  align-self: center;
  justify-self: center;
}
img {
  transform: scale(1);
  transition: all .15s 0s ease-in-out;
}
img.unselected {
  opacity: 0.4;
  transform: scale(0.92);
}


@media(min-width:768px){
  .Canvas-PoppingIntoTheSite-a{
      grid-template-rows: 59px 69px auto 179px 60px;    
  }

}

@media(min-width:1024px){
  .Canvas-PoppingIntoTheSite-a{
      grid-template-rows: 59px 69px auto 180px 60px;    
  }

}